.greaterIndent{
    text-indent: 10%;
    margin-top: 26px;
    
}
.program{

    height:fit-content;
}


.list li{
    font-size:30px ;
    margin-top: 35px;
}

@media (max-width:1100px){
   .list li{
        font-size: 18px;
        width: 95%;
    }

 }