@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

.About{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:35px;
    margin-bottom: 15px;
    font-size:1.5vw;
}
.white{
    width:80%;
    height: 20px;
    display:flex;
    justify-content: flex-start;
    background-color: white;
}

.white>div{
    background-color: red;
    width:10px;
    height:10px;
    margin:5px;
    border-radius: 100%;

}
#yellowbutton{
    background-color: rgb(252, 215, 7);
}
#greenbutton{
    background-color: rgb(27, 221, 27);
}
.program{
    
    background-color: rgb(15, 15, 15);
    height: 700px;
    width:80%;
    color:rgb(14, 233, 14);
    border-radius: 0% 0% 1% 1%;
    text-align: left;
    text-indent: 5%;
    font-family: 'Courier Prime', monospace;
    
}


.greaterIndent{
    text-indent: 10%;
    margin-top: 26px;
    margin-left: 5px;
}

@media (max-width:1100px){
    .program{
        font-size: 18px;
        width: 95%;
    }
    .white{
        width: 95%;
    }

 }

 @media (max-width:400px){
    .program{
        font-size: 14px;
        width: 95%;
    }
    .white{
        width: 95%;
    }

 }